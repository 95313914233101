<template>
<div id="404">
  <b-jumbotron bg-variant="warning"><b-skeleton type="input"/></b-jumbotron>
  <b-jumbotron
      header="404 Not Found"
      header-level="5"
      lead="What are you looking for?"
      bg-variant="danger"
      text-variant="white"
  >
    <b-jumbotron bg-variant="warning"><b-skeleton type="input"/></b-jumbotron>
  </b-jumbotron>

</div>
</template>

<script>
export default {
name: "404"
}
</script>

<style scoped>
* {
text-align: center;
}

</style>